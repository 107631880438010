import React from "react";
import SEO from "../components/SEO";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/Layout";

const Index = () => {
  const data = useStaticQuery(INDEX_PAGE_QUERY);

  return (
    <Layout>
      <SEO title="" />
      <div className="container">
        <div className="row anchor-fade">
          {data.posts.edges.map(({ node }) => {
            const { name } = node;
            const { frontmatter } = node.childMarkdownRemark;
            const { title, date } = frontmatter;
            const year = name.slice(0, 4);
            const month = name.slice(5, 7);
            const slug = name.slice(11, name.length);

            return (
              <div key={slug} className="col-md-8 col-md-offset-2">
                <h2>
                  <Link className="post-link" to={`/${year}/${month}/${slug}`}>
                    {title}
                  </Link>
                  <br />
                  <small>{date}</small>
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Index;

const INDEX_PAGE_QUERY = graphql`
  query {
    posts: allFile(
      sort: { order: DESC, fields: name }
      filter: { sourceInstanceName: { eq: "posts" } }
    ) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              date(formatString: "MMM D, YYYY")
            }
          }
        }
      }
    }
  }
`;
